import { Progress } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .h3 {
    margin-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 10px;
`;

export const handlePercent = (loaded: number, total: number) =>
  (loaded / total) * 100;

export const handleLabel = (percent: number) => {
  if (percent >= 100) {
    return 'Done';
  }
  return 'Uploading...';
};

interface UploadingProps {
  loaded: number;
  total: number;
}

const Uploading = ({ loaded, total }: UploadingProps) => {
  const percent = parseInt(handlePercent(loaded, total).toFixed(0), 10);
  return (
    <Wrapper>
      <IconWrapper>
        {total > 0 && <Progress type="circle" percent={percent} width={80} />}
      </IconWrapper>
      {total > 0 && <h3>{handleLabel(percent)}</h3>}
    </Wrapper>
  );
};

export default Uploading;
