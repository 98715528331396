import { Steps } from 'antd';

import DirectorProfileForm from './DirectorProfileForm/DirectorProfileForm';
import Submitted from './Submitted';
import Terms from './Terms';
import UploadID from './UploadID';

const { Step } = Steps;

export type VerifyDirectorStepperProps = {
  currentStep: number;
  director: { agentLpoaContent: string };
  handleTermsSubmit: (values: any) => void;
  isSubmitting: boolean;
  inviteId: string;
  handleUploadID: (values: any) => void;
  handleDirectorProfileSubmit: (values: any) => void;
  handleDirectorProfileCancel: () => void;
  isAccountOwner: boolean;
};

const VerifyDirectorStepper = ({
  currentStep,
  director,
  handleTermsSubmit,
  isSubmitting,
  inviteId,
  handleUploadID,
  handleDirectorProfileSubmit,
  handleDirectorProfileCancel,
  isAccountOwner,
}: VerifyDirectorStepperProps) => {
  return (
    <div>
      <Steps current={currentStep} size="small">
        <Step title="Accept Terms" />
        <Step title="Upload Proof of Identity" />
        <Step title="Complete Profile" />
      </Steps>
      <br />
      <br />
      {currentStep === 0 && (
        <Terms
          lpoaContent={director.agentLpoaContent}
          onSubmit={handleTermsSubmit}
          isSubmitting={isSubmitting}
        />
      )}
      {currentStep === 1 && (
        <UploadID
          directorID={inviteId}
          onSubmit={handleUploadID}
          isSubmitting={isSubmitting}
        />
      )}
      {currentStep === 2 && (
        <DirectorProfileForm
          // @ts-ignore
          initialValues={director}
          onSubmit={handleDirectorProfileSubmit}
          isSubmitting={isSubmitting}
          // eslint-disable-next-line no-console
          onCancel={() => handleDirectorProfileCancel()}
        />
      )}
      {currentStep === 3 && <Submitted isAccountOwner={isAccountOwner} />}
    </div>
  );
};

export default VerifyDirectorStepper;
