import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UAParser } from 'ua-parser-js';

import VerifyDirectorStepper from './components/VerifyDirectorStepper';
import { hasAccepted } from './utils';
import { analytics } from 'common/analytics/analytics';
import {
  DIRECTOR_VERIFY_CATEGORY,
  SIGNATURE_SUBMITTED,
  SIGNATURE_SAVED,
  DOCUMENTS_SAVED,
  TERMS_ACCEPTED,
  TERMS_SAVED,
  PROFILE_SUBMITTED,
  PROFILE_SAVED,
} from 'common/analytics/events';
import InlineLoading from 'common/components/InlineLoading/InlineLoading';
import Page from 'common/components/Page/Page';

import { acceptMembership } from 'common/lib/directorServices';
import { getIpAddress } from 'common/lib/ipServices';
import { uploadFileToS3 } from 'common/lib/S3FileServices';

import {
  fetchDirectorProfile,
  saveDirectorDocuments,
  saveDirectorProfile,
} from 'common/reducers/directorProfile';

export const VerifyDirector = () => {
  const dispatch = useDispatch();
  const director = useSelector((state: any) => state.directorProfile.profile);
  const token = useSelector((state: any) => state.user.token);
  const { inviteID } = useParams<{ inviteID: string }>();

  const [currentStep, setCurrentStep] = React.useState(0);
  const [loaded, setLoaded] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [headers, setHeaders] = React.useState({});

  const getHeaders = async () => {
    const ip = await getIpAddress();
    const parser = new UAParser();
    const userAgentInfo = parser.getResult();
    const response: Record<string, any> = {
      ...userAgentInfo,
      ...(ip as object),
    };
    setHeaders(response);
  };

  React.useEffect(() => {
    const fetchDirector = async () => {
      const response = await dispatch(fetchDirectorProfile(inviteID));
      // @ts-ignore
      if (hasAccepted(response.state)) {
        setCurrentStep(1);
      }
      setLoaded(true);
    };
    fetchDirector();
    getHeaders();
  }, [dispatch, inviteID]);

  const handleTermsSubmit = async (values: any) => {
    setIsSubmitting(true);
    const { fullName, signatureImage } = values;
    analytics.track(TERMS_ACCEPTED, {
      category: DIRECTOR_VERIFY_CATEGORY,
      values,
    });
    const signName = fullName.toUpperCase();
    const formattedHeaders = JSON.stringify(headers);
    analytics.track(SIGNATURE_SUBMITTED, {
      category: DIRECTOR_VERIFY_CATEGORY,
      signatureImage,
    });
    const { inviteId } = director;
    const uploadResult = await uploadFileToS3({
      file: signatureImage,
      s3Path: `directors/${inviteId}/signature.png`,
      useUniqueFileName: false,
    });
    analytics.track(SIGNATURE_SAVED, {
      category: DIRECTOR_VERIFY_CATEGORY,
      data: uploadResult.path,
    });
    acceptMembership(
      inviteId,
      uploadResult.path,
      signName,
      formattedHeaders
    ).then(() => {
      analytics.track(TERMS_SAVED, { category: DIRECTOR_VERIFY_CATEGORY });
      setIsSubmitting(false);
      setCurrentStep(1);
    });
  };

  const handleUploadID = async (documents: any) => {
    setIsSubmitting(true);
    const payload = { documents };
    const { inviteId } = director;
    const response = await dispatch(
      saveDirectorDocuments(payload, token, inviteId)
    );
    analytics.track(DOCUMENTS_SAVED, {
      category: DIRECTOR_VERIFY_CATEGORY,
      response,
    });
    setIsSubmitting(false);
    setCurrentStep(2);
  };

  const handleDirectorProfileSubmit = async (values: any) => {
    setIsSubmitting(true);
    analytics.track(PROFILE_SUBMITTED, {
      category: DIRECTOR_VERIFY_CATEGORY,
      values,
    });
    const response = await dispatch(saveDirectorProfile(values, token));
    analytics.track(PROFILE_SAVED, {
      category: DIRECTOR_VERIFY_CATEGORY,
      response,
    });
    setIsSubmitting(false);
    setCurrentStep(3);
  };

  const { inviteId, isAccountOwner } = director;
  return (
    <Page
      title="Director Verification"
      contentWidth="normal"
      subTitle={`${director.firstName} ${director.lastName}`}
    >
      {!loaded ? (
        <InlineLoading />
      ) : (
        <VerifyDirectorStepper
          currentStep={currentStep}
          director={director}
          handleTermsSubmit={handleTermsSubmit}
          isSubmitting={isSubmitting}
          inviteId={inviteId}
          handleUploadID={handleUploadID}
          handleDirectorProfileSubmit={handleDirectorProfileSubmit}
          handleDirectorProfileCancel={() => setCurrentStep(1)}
          isAccountOwner={isAccountOwner}
        />
      )}
    </Page>
  );
};

export default VerifyDirector;
