// Import Axios for making HTTP requests
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { axiosClient } from 'common/api/client';

const s3AxiosClient = axios.create();

// Define the base URL of your backend API
const API_BASE_URL = process.env.REACT_APP_API_URL;

// Add proper error types and constants
const S3_ERRORS = {
  UPLOAD_URL: 'Failed to fetch presigned upload URL.',
  UPLOAD_FILE: 'Failed to upload file.',
  DOWNLOAD_URL: 'Failed to fetch presigned download URL.',
  DOWNLOAD_FILE: 'Failed to download file.',
} as const;

// Add more specific error handling
interface S3Error extends Error {
  code?: string;
  status?: number;
}

// Add timeout configuration
const axiosConfig = {
  timeout: 30000, // 30 seconds
};

interface PresignedUrlResponse {
  url: string;
}

// Function to get a presigned URL for uploading a file
export const getPresignedUploadUrl = async (
  fileName: string
): Promise<string> => {
  try {
    const response = await axiosClient.post<PresignedUrlResponse>(
      `${API_BASE_URL}/documents/upload-url/`,
      {
        file_name: fileName,
      },
      axiosConfig
    );
    return response.data.url;
  } catch (error) {
    const s3Error = error as S3Error;
    throw new Error(`${S3_ERRORS.UPLOAD_URL} ${s3Error.message || ''}`);
  }
};

interface UploadFileParams {
  file: File;
  onProgress?: (progress: number) => void;
  s3Path: string;
  useUniqueFileName?: boolean;
}

interface UploadResult {
  url: string;
  path: string;
  fileName: string;
  generatedFileName: string;
  fileSize: number;
  fileType: string;
  uploadedAt: string;
}

/**
 * Uploads a file to S3 using a presigned URL
 * @param {Object} params - The upload parameters
 * @param {File} params.file - The file to upload
 * @param {function} [params.onProgress] - Optional callback function to handle upload progress
 * @param {string} params.s3Path - The destination path in S3 bucket
 * @returns {Promise<UploadResult>} Object containing upload details including URL and file metadata
 * @throws {Error} If file size exceeds limit or upload fails
 */
export const uploadFileToS3 = async ({
  file,
  onProgress,
  s3Path,
  useUniqueFileName = true,
}: UploadFileParams): Promise<UploadResult> => {
  try {
    // Validate file size against maximum limit
    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
    if (file.size > MAX_FILE_SIZE) {
      throw new Error('File size exceeds 100MB limit.');
    }

    // Only create unique filename if requested
    const fullPath = useUniqueFileName
      ? `${s3Path}/${uuidv4()}/${file.name || ''}`
      : s3Path;

    // Clean up any double slashes
    const cleanPath = fullPath.replace(/\/+/g, '/');

    // Get the presigned URL for upload
    const presignedUrl = await getPresignedUploadUrl(cleanPath);

    // Upload the file to S3 using the presigned URL
    await s3AxiosClient.put(presignedUrl, file, {
      ...axiosConfig,
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        // Skip progress calculation if total is undefined
        if (!progressEvent.total) {
          return;
        }

        // Calculate progress percentage and clamp between 0-100
        const progress = Math.min(
          Math.max(
            Math.round((progressEvent.loaded * 100) / progressEvent.total),
            0
          ),
          100
        );

        // Call progress callback if provided
        onProgress?.(progress);
      },
    });

    // Return metadata about the uploaded file
    return {
      url: presignedUrl,
      fileName: file.name, // Original filename
      generatedFileName: uuidv4(), // UUID-based filename
      fileSize: file.size,
      fileType: file.type,
      path: cleanPath, // Full path in S3
      uploadedAt: new Date().toISOString(),
    };
  } catch (error) {
    const s3Error = error as S3Error;
    throw new Error(`${S3_ERRORS.UPLOAD_FILE} ${s3Error.message || ''}`);
  }
};

// Function to get a presigned URL for downloading a file
export const getPresignedDownloadUrl = async (
  fileName: string
): Promise<string> => {
  const response = await axiosClient.post<PresignedUrlResponse>(
    `${API_BASE_URL}/documents/download-url/`,
    {
      file_name: fileName,
    },
    axiosConfig
  );
  return response.data.url;
};

// Function to download a file from S3 using a presigned URL
export const downloadFileFromS3 = async (fileName: string): Promise<void> => {
  const presignedUrl = await getPresignedDownloadUrl(fileName);
  window.open(presignedUrl, '_blank');
};
