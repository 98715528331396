import React from 'react';
import { Button } from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';

import { analytics } from 'common/analytics/analytics';
import { SIGNATURE_STARTED, SIGNATURE_RESET } from 'common/analytics/events';
import { dataURLtoBlob } from 'common/utils/fileUtils';

interface WrapperProps {
  error: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  margin-bottom: 2em;
  .signature-box-signature {
    position: relative;
    border: 1px dashed black;
    border-color: ${(props) =>
      props.error ? '#f5222d' : props.theme.colors.primary};
    margin-bottom: 8px;
    max-width: 400px;
    .sigCanvas {
      width: 100%;
      height: 100px;
      z-index: 2;
      position: relative;
    }
    &:after {
      content: '';
      height: 1px;
      width: 90%;
      position: absolute;
      bottom: 25px;
      left: 5%;
      background: #ccc;
    }
  }
  .signature-box-reset {
  }
`;

export type SignatureInputProps = {
  onSign: (blob: Blob) => void;
  onClear: () => void;
  error: boolean;
};

export const SignatureInput = ({
  onSign,
  onClear,
  error,
}: SignatureInputProps) => {
  const sigCanvas = React.useRef<any | null>(null);

  const handleTrim = () => {
    analytics.track(SIGNATURE_STARTED);
    // get the signature canvas
    const canvas = sigCanvas.current.getCanvas().toDataURL('image/png');
    // change the canvas to blob
    const blob = dataURLtoBlob(canvas);
    onSign(blob);
  };

  const handleClear = () => {
    analytics.track(SIGNATURE_RESET);
    sigCanvas.current.clear();
    onClear();
  };

  return (
    <Wrapper className="signature-box" error={error}>
      <div className="signature-box-signature">
        <SignatureCanvas
          velocityFilterWeight={1}
          canvasProps={{ className: 'sigCanvas' }}
          ref={sigCanvas}
          onEnd={() => handleTrim()}
          dotSize={2}
          clearOnResize={false}
          backgroundColor="rgba(255,255,255,0)"
        />
      </div>
      <div className="signature-box-reset">
        <Button onClick={() => handleClear()} danger>
          Start again
        </Button>
      </div>
    </Wrapper>
  );
};

export default SignatureInput;
