import { Collapse } from 'antd';

const { Panel } = Collapse;

export const createMarkup = (html: string) => {
  return { __html: html };
};

type Item = {
  title: string;
  text: string;
};

type FAQListProps = {
  items: Item[];
};

const FAQList = ({ items }: FAQListProps) => (
  <div>
    <Collapse
      accordion
      // onChange={onChange}
    >
      {items.map((item) => (
        <Panel header={item.title} key={item.title}>
          {/*  eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={createMarkup(item.text)} />
        </Panel>
      ))}
    </Collapse>
  </div>
);

export default FAQList;
