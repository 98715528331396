import { Form, Input, Select, Divider } from 'antd';

import { provinces } from './provinces';

import { includesPOBox } from 'common/utils/validateAddress';
// import { removeSpecialCharacters } from 'common/utils/strings';

const FormItem = Form.Item;
const { Option } = Select;

const handlePOBox = (_rule: any, value: string) => {
  if (includesPOBox(value)) {
    return Promise.reject(new Error('Physical address cannot be a PO Box'));
  }
  return Promise.resolve();
};

export type AddressFieldsProps = {
  labelPrefix: string;
  type?: string;
  title?: string;
  required?: boolean;
};

const AddressFields = ({
  labelPrefix,
  type = '',
  title = '',
  required = undefined,
}: AddressFieldsProps) => (
  <div>
    {title && <Divider orientation="left">{title}</Divider>}
    <FormItem
      name={`${labelPrefix}Street`}
      label="Street Address"
      rules={[
        {
          required,
          message: 'Required',
        },
        {
          validator: type === 'physical' ? handlePOBox : undefined,
        },
      ]}
      validateFirst
      // normalize={(value) => value && removeSpecialCharacters(value)}
    >
      <Input size="large" autoComplete="shipping address-line1" />
    </FormItem>
    <FormItem
      name={`${labelPrefix}City`}
      label="City"
      rules={[
        {
          required,
          message: 'Required',
        },
      ]}
      // normalize={(value) => value && removeSpecialCharacters(value)}
    >
      <Input size="large" autoComplete="shipping address-level2" />
    </FormItem>
    <FormItem
      name={`${labelPrefix}Province`}
      label="Province"
      rules={[
        {
          required,
          message: 'Required',
        },
      ]}
    >
      <Select
        size="large"
        placeholder="Please select a province"
        // @ts-ignore
        autoComplete="shipping address-level1"
      >
        {provinces.map((province) => (
          <Option key={province.label} value={province.value}>
            {province.label}
          </Option>
        ))}
      </Select>
    </FormItem>
    <FormItem
      name={`${labelPrefix}PostCode`}
      label="Postal Code"
      rules={[
        {
          required,
          message: 'Required',
        },
        {
          len: 4,
          message: 'Postal code must be 4 digits',
        },
      ]}
    >
      <Input size="large" type="number" autoComplete="shipping postal-code" />
    </FormItem>
  </div>
);

export default AddressFields;
