import dayjs from 'dayjs';
import { legacyParse } from '@date-fns/upgrade/v2';
import currency from 'currency.js';
import {
  startOfMonth,
  endOfMonth,
  format,
  startOfYear,
  addMonths,
  subYears,
  endOfYear,
} from 'date-fns';

export const formatCurrency = (amount: number) => {
  const amountToConvert = amount || 0;
  return currency(amountToConvert, {
    symbol: 'R ',
    fromCents: true,
    precision: 2,
  }).format();
};

export const getCurrentMonthDates = () => {
  const now = new Date();
  const startDate = format(startOfMonth(now), 'yyyy-MM-dd');
  const endDate = format(endOfMonth(now), 'yyyy-MM-dd');

  return { startDate, endDate };
};

export const getCurrentYearDates = () => {
  const now = new Date();
  const startDate = format(startOfYear(now), 'yyyy-MM-dd');
  const endDate = format(endOfMonth(now), 'yyyy-MM-dd');

  return { startDate, endDate };
};

export const getFinancialYearMomentDates = () => {
  const now = new Date();
  // ? Checks to see if the current date falls within the first 2 months of the year
  // ? Returns the correct year then instead of the year it's currently in
  // ! This assumes the financial year starts in March
  if (now.getMonth() < 3) {
    return {
      // ? Returns the start date of the financial year
      startOfFinancialYear: dayjs(startOfYear(addMonths(subYears(now, 1), 2))),
      endOfFinancialYear: dayjs(startOfYear(addMonths(now, 2))),
      startOfPreviousFinancialYear: dayjs(
        subYears(startOfYear(addMonths(subYears(now, 1), 2)), 1)
      ),
      endOfPreviousFinancialYear: dayjs(
        subYears(startOfYear(addMonths(now, 2)), 1)
      ),
    };
  }

  return {
    startOfFinancialYear: dayjs(addMonths(startOfYear(now), 2)),
    endOfFinancialYear: dayjs(addMonths(endOfYear(now), 2)),
    startOfPreviousFinancialYear: dayjs(
      addMonths(subYears(startOfYear(now), 1), 2)
    ),
    endOfPreviousFinancialYear: dayjs(
      addMonths(subYears(endOfYear(now), 1), 2)
    ),
  };
};

export const formatDate = (date: string) =>
  format(legacyParse(date), 'dd MMM yyyy');
