export const includesPOBox = (address: string) => {
  let response = false;
  const formattedString = address.toLowerCase();
  const invalidStrings = [
    'po box',
    'p.o. box',
    'p.o.box',
    'p.o box',
    'po. box',
    'p. o. box',
    'p. o box',
    'p o box',
    'private bag',
    'privatebag',
    'p/bag',
    'p/ bag',
    'p /bag',
    'p / bag',
  ];
  invalidStrings.map((invalidString) => {
    if (formattedString.includes(invalidString)) {
      response = true;
    }
    return null;
  });
  return response;
};
