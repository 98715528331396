import { Form, Input } from 'antd';

import {
  removeAllWhiteSpace,
  removeSpecialCharacters,
} from 'common/utils/strings';
import { validateIdentityNumber } from 'common/utils/validateIdentityNumber';

const FormItem = Form.Item;

type IdentityNumberFieldProps = {
  label?: string;
  id?: string;
};

const normalizeIdentityNumber = (value: any) => {
  let normalizedValue = value;
  normalizedValue = removeSpecialCharacters(normalizedValue);
  normalizedValue = removeAllWhiteSpace(normalizedValue);
  return normalizedValue;
};

const IdentityNumberField = ({
  label = 'ID/Passport Number',
  id = 'identityNumber',
}: IdentityNumberFieldProps) => (
  <FormItem
    name={id}
    label={label}
    rules={[
      {
        required: true,
        message: 'Required',
      },
      {
        validator: validateIdentityNumber,
      },
    ]}
    normalize={(value) => normalizeIdentityNumber(value)}
    validateFirst
  >
    <Input size="large" type="number" />
  </FormItem>
);

export default IdentityNumberField;
