import { Card, Button } from 'antd';
import { useHistory } from 'react-router-dom';

export type SubmittedProps = {
  isAccountOwner: boolean;
};

const Submitted = ({ isAccountOwner }: SubmittedProps) => {
  const history = useHistory();
  return (
    <div>
      <Card title="Documents received!" style={{ marginBottom: '2em' }}>
        <p className="mb-4">
          Our team is reviewing your identity documents{' '}
          <span role="img" aria-label="Eyes">
            👀
          </span>
          , this can take up to 24 hours
        </p>
        {isAccountOwner ? (
          <Button
            type="primary"
            onClick={() => history.push('/onboarding/status')}
          >
            Follow registration status
          </Button>
        ) : (
          <p>
            <strong>Close this window and have a fabulous day</strong>
          </p>
        )}
      </Card>
    </div>
  );
};

export default Submitted;
