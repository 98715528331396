import styled from 'styled-components';

import ChecklistItem, { Item } from './ChecklistItem';

const Wrapper = styled.div``;

type ChecklistProps = {
  items: Item[];
};

const Checklist = ({ items }: ChecklistProps) => {
  return (
    <div>
      <Wrapper>
        {items.map((item) => (
          <ChecklistItem key={item.id} id={item.id} label={item.label} />
        ))}
      </Wrapper>
    </div>
  );
};

export default Checklist;
