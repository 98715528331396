import { useEffect, useState, useCallback } from 'react';
import { Alert } from 'antd';
import qs from 'qs';
import { Cookies, withCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';

import { analytics } from 'common/analytics/analytics';
import { LOGIN_SUCCESS, LOGIN_ERROR } from 'common/analytics/events';
import LoadingPage from 'common/components/LoadingPage';
import { clearUserData } from 'common/lib/user';
import { setUserCurrentCompanyById } from 'common/reducers/companies';
import { fetchUserAndCompanies } from 'common/reducers/user';
import { handleAuthRedirectUrl } from 'common/utils/authUtils';
import { setToken } from 'common/utils/cookiesUtils';

interface TokenLoginProps {
  cookies: Cookies;
}

const TokenLogin = ({ cookies }: TokenLoginProps) => {
  const [checking, setChecking] = useState(true);
  const [invalidToken, setInvalidToken] = useState(false);
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleFetchData = useCallback(async () => {
    try {
      const query = location.search;
      const values = qs.parse(query, { ignoreQueryPrefix: true });
      setToken(cookies, token);
      if (values && values.co) {
        await dispatch(setUserCurrentCompanyById(values.co, token));
      }

      const response = (await dispatch(
        fetchUserAndCompanies(token)
      )) as unknown as any[];
      const [user, company, companies, order] = response;
      analytics.track(LOGIN_SUCCESS, user);

      const companyState = company?.state;
      const orderState = order?.state;

      return history.push(
        handleAuthRedirectUrl({
          companyState,
          orderState,
          query,
          companies,
        }) as string
      );
    } catch (error) {
      analytics.track(LOGIN_ERROR);
      setChecking(false);
      setInvalidToken(true);
      return null;
    }
  }, [location.search, token, history, cookies, dispatch]);

  useEffect(() => {
    let mounted = true;

    const init = async () => {
      if (mounted) {
        clearUserData();
        await handleFetchData();
      }
    };

    init();

    return () => {
      mounted = false;
    };
  }, [handleFetchData]);

  return (
    <div>
      {checking && <LoadingPage />}
      {invalidToken && (
        <div className="max-w-500 m-6 mx-auto">
          <Alert
            message="Login failed"
            description={
              <div>
                Your link is either incorrect or expired. Please{' '}
                <Link to="/login">request a new login link</Link>.
              </div>
            }
            type="error"
          />
        </div>
      )}
    </div>
  );
};

export default withCookies(TokenLogin);
