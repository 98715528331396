import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import AppBar from './AppBar/AppBar';
import Content from './Content';
import DashboardMenu from './DashboardMenu/DashboardMenu';
import Footer from './Footer/Footer';
import Upsell from './Upsell/Upsell';
import { showComplianceStatus } from './utils';
import NoticeList from '../NoticeList/NoticeList';
import NoticeType from '../NoticeList/NoticeType';
import CompanyComplianceBanner from 'modules/compliance/components/CompanyComplianceBanner';
import { canAccess } from 'modules/subscriptions/utils';

const appBarHeight = 64;

const AppBarContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const MainContainer = styled.div`
  display: flex;
  min-height: 100vh;
  margin-top: -${appBarHeight}px;
  padding-top: ${appBarHeight}px;
  background: white;
  @media (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 60px;
  }
`;

const DashboardMenuContainer = styled.div`
  background: #fafafa;
  border-right: 1px solid ${(props) => props.theme.colors['border-color']};
  flex: 0 0 220px;
  @media (max-width: 992px) {
    flex: 0 0 auto;
  }
`;

const ContentContainer = styled.div`
  background: white;
  flex: 1;
  padding: 24px 16px;
  .company-compliance-status {
    margin-bottom: 2em;
  }
`;

export type PageProps = {
  showDashboardMenu?: boolean;
  contentWidth?: string;
  title?: string;
  children?: React.ReactNode;
  onBack?: () => void;
  subTitle?: string;
  notices?: NoticeType[];
  minRole?: 'none' | 'starter' | 'pro' | 'pro_vat' | 'family';
};

export const Page = ({
  showDashboardMenu = false,
  contentWidth = 'full',
  title = '',
  children = null,
  // @ts-ignore
  onBack = null,
  subTitle = '',
  notices = [],
  minRole,
}: PageProps) => {
  const location = useLocation();
  const { pathname } = location;
  const currentCompany = useSelector(
    (state: any) => state.companies.currentCompany
  );

  return (
    <>
      <AppBarContainer>
        <AppBar />
      </AppBarContainer>
      <MainContainer>
        {showDashboardMenu && (
          <DashboardMenuContainer>
            <DashboardMenu />
          </DashboardMenuContainer>
        )}
        <ContentContainer>
          {notices && notices.length > 0 && <NoticeList notices={notices} />}
          <Content
            // @ts-ignore
            contentWidth={contentWidth}
            title={title}
            onBack={onBack}
            subTitle={subTitle}
          >
            {canAccess(currentCompany, minRole) ? (
              <>
                {showComplianceStatus(pathname, currentCompany.state) && (
                  <CompanyComplianceBanner />
                )}
                {children}
              </>
            ) : (
              <Upsell requiredPlan={minRole} />
            )}
          </Content>
        </ContentContainer>
      </MainContainer>
      <Footer />
    </>
  );
};

export default Page;
