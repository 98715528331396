import Fuse from 'fuse.js';

import adminApi from 'common/api';
import { Contact } from 'modules/accounting/Contacts/types';

const fuseOptions = {
  keys: ['name', 'email'],
  threshold: 0,
  ignoreLocation: true,
};

export const handleFilters = (
  contacts: Contact[],
  searchString?: string,
  type?: string
) => {
  let filteringContacts = [...contacts];

  if (type?.toLocaleLowerCase() !== 'all') {
    filteringContacts = filteringContacts.filter(
      (contact) => contact.typeOf === type?.toLocaleLowerCase()
    );
  }

  if (searchString) {
    const fuse = new Fuse(filteringContacts, fuseOptions);
    const result = fuse.search(searchString);
    filteringContacts = result.map((searchedContact) => searchedContact.item);
  }

  return filteringContacts;
};

export const handleCreateContacts = async (data: Partial<Contact>) => {
  return adminApi.accounting.contacts.createContact(data);
};

export const handleGetContacts = async (id?: string) => {
  let contactResponse = [];

  if (id) {
    const requestResponse =
      await adminApi.accounting.contacts.getContactById(id);
    contactResponse = [{ ...requestResponse }];
  } else {
    contactResponse = await adminApi.accounting.contacts.getContacts();
  }

  // sort by id and reverse to get the latest
  contactResponse.sort((a: Contact, b: Contact) => Number(a.pk) - Number(b.pk));
  contactResponse.reverse();

  return contactResponse;
};

export const handleUpdateContact = async (
  id: string | number,
  data: Partial<Contact>
) => {
  return adminApi.accounting.contacts.updateContact(id, data);
};

export const handleDeleteContact = async (id: string | number) => {
  return adminApi.accounting.contacts.deleteContact(id);
};

// Helper function to fix physical address key
export const fixPhysicalAddress = (data: Partial<Contact>) => ({
  ...data,
  physicalAddress: {
    ...data.physicalAddress,
    addressLine_1: data.physicalAddress?.addressLine1 ?? '',
    addressLine_2: data.physicalAddress?.addressLine2 ?? '',
  },
});
