import SignatureDocument from 'common/components/SignatureDocument';

export type SignatureDocumentSubmitPayload = {
  fullName: string;
  signatureImage: Blob;
};

export type TermsProps = {
  onSubmit: (payload: SignatureDocumentSubmitPayload) => void;
  isSubmitting: boolean;
  lpoaContent: string;
};

const Terms = ({ onSubmit, isSubmitting, lpoaContent }: TermsProps) => (
  <SignatureDocument
    onSubmit={onSubmit}
    isSubmitting={isSubmitting}
    content={lpoaContent}
  />
);

export default Terms;
