import { Plan, PlanContent, ActiveSubscription, PlanTitle } from './types';

const roles = ['none', 'starter', 'pro', 'pro_vat', 'family'];
export type accessLevelRoleTypes =
  | 'none'
  | 'starter'
  | 'pro'
  | 'pro_vat'
  | 'family';
// currentCompany object from state
// minRole: Minimum role to test for

export const canAccess = (
  currentCompany: any,
  minRole?: accessLevelRoleTypes
) => {
  if (currentCompany?.isBooksEnabled || !minRole) {
    return true;
  }

  if (minRole) {
    const accessRank = roles.indexOf(currentCompany.accessLevel);
    const minRank = roles.indexOf(minRole);

    return accessRank >= minRank;
  }

  return false;
};

// TODO: need to relook this script, its mega complicated
// TODO: should be dynamic to handle alias plans
// TODO: should handle subscriptions with cancelling etc states

export const formatSubscriptionsAsPlans = (
  activeSubscriptions: ActiveSubscription[],
  plansContent: PlanContent[]
): Plan[] => {
  const freePlanContent = plansContent.find(
    (plan) => plan.slug === 'free_subscription'
  );
  const proPlanContent = plansContent.find(
    (plan) => plan.slug === 'pro_subscription'
  );
  const freePlan: Plan = {
    title: freePlanContent?.title as PlanTitle,
    isActive: true,
    slug: 'free_subscription',
    price: 0,
    description: freePlanContent?.description as string,
    includedTitle: freePlanContent?.includedTitle as string,
    features: freePlanContent?.features as string[],
  };

  const proPlan: Plan = {
    title: proPlanContent?.title as PlanTitle,
    isActive: false,
    slug: 'pro_subscription',
    price: 99500,
    description: proPlanContent?.description as string,
    includedTitle: proPlanContent?.includedTitle as string,
    features: proPlanContent?.features as string[],
  };

  const isProSubscriptionActive =
    activeSubscriptions.length > 0 &&
    activeSubscriptions.some(
      (subscription) =>
        subscription.item.companyservice.service.slug === 'pro_subscription' &&
        subscription.status === 'active'
    );

  if (isProSubscriptionActive) {
    freePlan.isActive = false;
    proPlan.isActive = true;
    proPlan.price =
      activeSubscriptions[0].item.companyservice.subscriptionPrice;
  }

  return [freePlan, proPlan];
};
