/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  Form,
  Button,
  Input,
  Checkbox,
  Card,
  Select,
  Row,
  Col,
  InputNumber,
} from 'antd';
import styled from 'styled-components';

import { COUNTRIES } from './countries';
import DirectorProfileFormFieldsTypes from './DirectorProfileFormFieldsTypes';

import { analytics } from 'common/analytics/analytics';
import {
  DIRECTOR_VERIFY_CATEGORY,
  PROFILE_FORM_SUBMITTED,
  PROFILE_FORM_ERROR,
} from 'common/analytics/events';
import AddressFields from 'common/components/Forms/AddressFields';
import IdentityNumberField from 'common/components/Forms/IdentityNumberField';
import MobileNumberField from 'common/components/Forms/MobileNumberField';
import {
  removeAllWhiteSpace,
  removeSpecialCharacters,
} from 'common/utils/strings';

const { Option } = Select;

const normalizeIdentityNumber = (value: any) => {
  let normalizedValue = value;
  normalizedValue = removeSpecialCharacters(normalizedValue);
  normalizedValue = removeAllWhiteSpace(normalizedValue);
  return normalizedValue;
};

const DOBWrapper = styled.div`
  max-width: 300px;
`;

export type DirectorProfileFormProps = {
  initialValues?: DirectorProfileFormFieldsTypes;
  onSubmit: (arg: DirectorProfileFormFieldsTypes) => void;
  onCancel: () => void;
  isSubmitting: boolean;
};

export const isPhysicalAddressSameAsPostalAddress = (
  initialValues: DirectorProfileFormFieldsTypes
) => {
  let result = true;
  const {
    physicalStreet,
    physicalCity,
    physicalProvince,
    physicalPostCode,
    postalStreet,
    postalCity,
    postalProvince,
    postalPostCode,
  } = initialValues;
  if (
    physicalStreet !== postalStreet ||
    physicalCity !== postalCity ||
    physicalProvince !== postalProvince ||
    physicalPostCode !== postalPostCode
  ) {
    result = false;
  }
  return result;
};

const DirectorProfileForm = ({
  // @ts-ignore
  initialValues = {},
  onSubmit,
  onCancel,
  isSubmitting,
}: DirectorProfileFormProps) => {
  const [form] = Form.useForm();

  const onFinish = (values: DirectorProfileFormFieldsTypes) => {
    analytics.track(PROFILE_FORM_SUBMITTED, {
      category: DIRECTOR_VERIFY_CATEGORY,
      values,
    });
    onSubmit(values);
  };

  const onFinishFailed = ({ errorFields }: any) => {
    analytics.track(PROFILE_FORM_ERROR, {
      category: DIRECTOR_VERIFY_CATEGORY,
      errorFields,
    });
  };

  const [hasPostalAddress, setHasPostalAddress] = React.useState(false);
  const [isSouthAfricanCitizen, setIsSouthAfricanCitizen] =
    React.useState(true);

  React.useEffect(() => {
    if (initialValues) {
      const { originCountry } = initialValues;
      if (originCountry && originCountry !== 'South Africa') {
        setIsSouthAfricanCitizen(false);
      }
      if (!isPhysicalAddressSameAsPostalAddress(initialValues)) {
        setHasPostalAddress(true);
      }
    }
  }, [initialValues]);

  // @ts-ignore
  const daysArray = [...Array(32).keys()];
  daysArray.shift();

  const pad2 = (number: number) => {
    return (number < 10 ? '0' : '') + number;
  };

  React.useEffect(() => {
    const currentOriginCountry = form.getFieldValue('originCountry');
    if (!isSouthAfricanCitizen && currentOriginCountry === 'South Africa') {
      form.setFieldsValue({ originCountry: '' });
    }
  }, [form, isSouthAfricanCitizen]);

  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        initialValues={initialValues}
        form={form}
      >
        <Card title="Enter Your Details" style={{ marginBottom: '2em' }}>
          <Form.Item
            name="firstName"
            label="First Name(s)"
            extra="Must match your ID document"
            rules={[
              {
                required: true,
                message: 'Required',
              },
              {
                // This regex matches any character except square brackets, curly brackets, and parentheses
                // eslint-disable-next-line no-useless-escape
                pattern: /^[^\[\]{}()]*$/,
                message: 'Special characters are not allowed',
              },
            ]}
          >
            <Input size="large" autoComplete="given-name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Surname"
            extra="Must match your ID document"
            rules={[
              {
                required: true,
                message: 'Required',
              },
            ]}
          >
            <Input size="large" autoComplete="family-name" />
          </Form.Item>
          <MobileNumberField extra="Example: 0825650909" showIcon={false} />
          <Checkbox
            onChange={() => setIsSouthAfricanCitizen(!isSouthAfricanCitizen)}
            checked={isSouthAfricanCitizen}
            defaultChecked={isSouthAfricanCitizen}
          >
            I am a South African citizen
          </Checkbox>
          <br />
          <br />
          {isSouthAfricanCitizen ? (
            <IdentityNumberField label="ID Number" id="identityNumber" />
          ) : (
            <div>
              <Form.Item
                name="identityNumber"
                label="Passport Number"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
                normalize={(value) => normalizeIdentityNumber(value)}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                name="originCountry"
                label="Country of Origin"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  size="large"
                  placeholder="Please select a country"
                >
                  {COUNTRIES.map((i) => (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <DOBWrapper>
                <div className="ant-form-item-label">
                  <label
                    className="ant-form-item-required"
                    title="Date of Birth"
                  >
                    Date of Birth
                  </label>
                </div>
                <Row gutter={8}>
                  <Col xs={6}>
                    <Form.Item
                      name="birthDay"
                      rules={[
                        {
                          required: true,
                          message: 'Required',
                        },
                      ]}
                    >
                      <Select size="large" placeholder="Day">
                        {daysArray.map((d) => (
                          <Option key={d} value={pad2(d)}>
                            {pad2(d)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      name="birthMonth"
                      rules={[
                        {
                          required: true,
                          message: 'Required',
                        },
                      ]}
                    >
                      <Select size="large" placeholder="Month">
                        <Option value="01">January</Option>
                        <Option value="02">February</Option>
                        <Option value="03">March</Option>
                        <Option value="04">April</Option>
                        <Option value="05">May</Option>
                        <Option value="06">June</Option>
                        <Option value="07">July</Option>
                        <Option value="08">August</Option>
                        <Option value="09">September</Option>
                        <Option value="10">October</Option>
                        <Option value="11">November</Option>
                        <Option value="12">December</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={6}>
                    <Form.Item
                      name="birthYear"
                      rules={[
                        {
                          required: true,
                          message: 'Required',
                        },
                      ]}
                    >
                      <InputNumber
                        size="large"
                        placeholder="Year"
                        min={1918}
                        max={2000}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </DOBWrapper>
            </div>
          )}
        </Card>
        <Card title="Enter Your Home Address" style={{ marginBottom: '2em' }}>
          <AddressFields labelPrefix="physical" required />
          <Checkbox
            onChange={() => setHasPostalAddress(!hasPostalAddress)}
            checked={hasPostalAddress}
          >
            I have a different postal address
          </Checkbox>
        </Card>
        {hasPostalAddress && (
          <Card
            title="Enter Your Postal Address"
            style={{ marginBottom: '2em' }}
          >
            <AddressFields labelPrefix="postal" required />
          </Card>
        )}
        <div style={{ float: 'right' }}>
          <Button
            onClick={() => onCancel()}
            size="large"
            style={{ marginRight: '1em' }}
          >
            Back
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {isSubmitting ? 'submitting' : 'Save and continue'}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default DirectorProfileForm;
