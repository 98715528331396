import { MembershipStateType } from './MembershipStateType';

export const notAcceptedStates = ['new', 'invited', 'viewed'];

export const hasAccepted = (membershipState: MembershipStateType) => {
  let response = false;
  if (!notAcceptedStates.find((n) => n === membershipState)) {
    response = true;
  }
  return response;
};
