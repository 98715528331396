/* eslint-disable react/no-danger */
import { Card } from 'antd';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 2em;
  .document-viewer-content {
    max-height: 300px;
    overflow-y: auto;
  }
`;

export type DocumentViewerProps = {
  content: string;
};

const DocumentViewer = ({ content }: DocumentViewerProps) => (
  <Wrapper className="document-viewer">
    <Card title="Review Document">
      <div className="document-viewer-content prose prose-sm max-w-full">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </Card>
  </Wrapper>
);

DocumentViewer.propTypes = {
  content: PropTypes.string.isRequired,
};

export default DocumentViewer;
