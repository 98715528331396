import { Switch } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const Label = styled.div`
  flex-grow: 1;
  margin-right: 16px;
`;

const SwitchWrapper = styled.div`
  flex-grow: 0;
  .ant-switch {
    background-color: #ff4d4f;
  }
  .ant-switch-checked {
    background-color: #52c41a;
  }
`;

export type Item = {
  id: string;
  label: string;
};

export const ChecklistItem = ({ id, label }: Item) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <SwitchWrapper>
        {/* @ts-ignore */}
        <Switch id={id} checkedChildren="yes" unCheckedChildren="no" />
      </SwitchWrapper>
    </Wrapper>
  );
};

export default ChecklistItem;
