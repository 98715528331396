import { Cookies } from 'react-cookie';

const domain = process.env.REACT_APP_DOMAIN || '';
const expires = new Date(Number(new Date()) + 315360000000);

export const setToken = (cookies: Cookies, token: string) => {
  cookies.set('token', token, {
    domain,
    path: '/',
    expires,
  });
};

export const deleteToken = () => {
  const currentDomain = domain || window.location.hostname;
  // Try different domain variations to ensure cookie is deleted
  const domains = [
    currentDomain,
    `.${currentDomain}`,
    window.location.hostname,
    `.${window.location.hostname}`,
  ];

  domains.forEach((d) => {
    document.cookie = `token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${d};path=/`;
  });
};
