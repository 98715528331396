import { Card } from 'antd';
import styled from 'styled-components';

import Requirements from './components/Requirements/Requirements';
import UploadArea from './components/UploadArea';
import FAQList from 'common/components/FAQList/FAQList';

const Wrapper = styled.div`
  margin-bottom: 2em;
`;

export const Description = styled.div`
  margin-bottom: 2em;
`;

export const FAQWrapper = styled.div`
  margin-bottom: 2em;
`;

export const labels = {
  idBook: 'certified ID',
  idCardFront: 'ID Card (front)',
  idCardBack: 'ID Card (back)',
  passport: 'certified passport',
  selfie: 'Selfie with ID',
};

export const generateFaqList = (type: string) => {
  let label = '';
  switch (type) {
    case 'idBook':
      label = 'ID document';
      break;
    case 'passport':
      label = 'passport';
      break;
    default:
      break;
  }
  return [
    {
      title: `Where to get your ${label} certified`,
      text: `You can get your ${label} certified by a commissioner of oaths at your local <a href="https://www.google.com/search?q=police+station" target="blank">police station</a>, <a href="https://www.google.com/search?q=post+office" target="blank">post office</a> or any <a href="https://www.google.com/search?q=notary" target="blank">notary</a>.`,
    },
    {
      title: 'Which documents are accepted',
      text: '<strong>South African citizens</strong> must use a certified copy of their <strong>green bar-coded ID book</strong> or <strong>smart ID card</strong>. <br/><br/><strong>Non-South African citizens</strong> must use a certified copy of their <strong>passport</strong>.',
    },
  ];
};

export const handleLabel = (type: string) => {
  const { idBook, idCardFront, idCardBack, passport, selfie } = labels;
  switch (type) {
    case 'idBook':
      return idBook;
    case 'idCardFront':
      return idCardFront;
    case 'idCardBack':
      return idCardBack;
    case 'passport':
      return passport;
    case 'selfie':
      return selfie;
    default:
      return '';
  }
};

export const createMarkup = (html: string) => {
  return { __html: html };
};

interface IDUploaderProps {
  type: string;
  description: string;
  requirements: Array<{
    id: string;
    label: string;
  }>;
  requirementsDescription: string;
  handleUpload: (file: File, type: string) => void;
  isUploading: boolean;
  uploaded: boolean;
  loaded: number;
  total: number;
}

const IDUploader = ({
  type,
  description = '',
  requirements,
  requirementsDescription,
  handleUpload,
  isUploading,
  uploaded,
  loaded,
  total,
}: IDUploaderProps) => {
  const label = handleLabel(type);
  return (
    <Wrapper>
      <Card title={`Upload ${label}`}>
        {description && (
          <Description dangerouslySetInnerHTML={createMarkup(description)} />
        )}
        <FAQWrapper>
          <FAQList items={generateFaqList(type)} />
        </FAQWrapper>
        <Requirements
          description={requirementsDescription}
          requirements={requirements}
        />
        <UploadArea
          type={type}
          label={label}
          handleUpload={handleUpload}
          isUploading={isUploading}
          uploaded={uploaded}
          loaded={loaded}
          total={total}
        />
      </Card>
    </Wrapper>
  );
};

export default IDUploader;
