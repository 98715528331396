import { useState } from 'react';
import { Button, message, RadioChangeEvent } from 'antd';
import PropTypes from 'prop-types';

import ChooseIdType from './components/ChooseIdType';
import IDUploader from './components/IDUploader';
import { idBookRequirements, passportRequirements } from './requirements';
import { analytics } from 'common/analytics/analytics';
import {
  DIRECTOR_VERIFY_CATEGORY,
  ID_TYPE_SELECTED,
  DOCUMENT_UPLOAD_STARTED,
  DOCUMENT_UPLOAD_SUCCESS,
  DOCUMENTS_SUBMITTED,
  DOCUMENTS_ERROR,
} from 'common/analytics/events';

import { uploadFileToS3 } from 'common/lib/S3FileServices';

import { heicToJpg } from 'common/utils/fileUtils';

interface Document {
  type: string;
  isUploading: boolean;
  uploaded: boolean;
  storage_url: string;
  label: string;
  loaded: number;
  total: number;
}

interface UploadIDProps {
  directorID: string;
  onSubmit: (payload: any) => void;
  isSubmitting: boolean;
}

const UploadID = ({ directorID, onSubmit, isSubmitting }: UploadIDProps) => {
  const [document, setDocument] = useState<Document>({
    type: '',
    isUploading: false,
    uploaded: false,
    storage_url: '',
    label: '',
    loaded: 0,
    total: 0,
  });
  const [idType, setIdType] = useState('');

  const onChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    analytics.track(ID_TYPE_SELECTED, {
      category: DIRECTOR_VERIFY_CATEGORY,
      value,
    });
    setIdType(value);
    setDocument({
      type: value,
      isUploading: false,
      uploaded: false,
      storage_url: '',
      label: '',
      loaded: 0,
      total: 0,
    });
  };

  const handleUpload = async (file: File, type: string) => {
    const newDocument = {
      ...document,
      isUploading: true,
    };
    setDocument(newDocument);

    analytics.track(DOCUMENT_UPLOAD_STARTED, {
      category: DIRECTOR_VERIFY_CATEGORY,
      type,
    });

    let fileResult = file;
    if (file.type === 'image/heic') {
      fileResult = await heicToJpg(file);
    }

    // Create unique filename by adding timestamp
    const timestamp = Date.now();
    const fileExtension = fileResult.name.split('.').pop();
    const newFileName = `ID_FRONT-${timestamp}.${fileExtension}`;

    // Create new File object with renamed file
    const renamedFile = new File([fileResult], newFileName, {
      type: fileResult.type,
    });

    const uploadResult = await uploadFileToS3({
      file: renamedFile,
      s3Path: `directors/${directorID}/${type}/`,
      onProgress: (progress) => {
        setDocument((prevDocument) => ({
          ...prevDocument,
          loaded: progress,
          total: 100,
        }));
      },
    });

    const handleLabel = () => {
      switch (type) {
        case 'idBook':
          return 'ID_FRONT';
        case 'passport':
          return 'ID_FRONT';
        default:
          return '';
      }
    };

    setDocument((prevDocument) => ({
      ...prevDocument,
      isUploading: false,
      uploaded: true,
      storage_url: uploadResult.path,
      label: handleLabel(),
    }));

    analytics.track(DOCUMENT_UPLOAD_SUCCESS, {
      category: DIRECTOR_VERIFY_CATEGORY,
      document,
    });
    return false;
  };

  const handleSubmit = () => {
    analytics.track(DOCUMENTS_SUBMITTED, {
      category: DIRECTOR_VERIFY_CATEGORY,
      document,
    });

    if (document.isUploading) {
      analytics.track(DOCUMENTS_ERROR, {
        category: DIRECTOR_VERIFY_CATEGORY,
        error: 'documents still uploading',
      });
      return message.error('Please wait for files to finish uploading', 5);
    }

    if (!document.uploaded) {
      analytics.track(DOCUMENTS_ERROR, {
        category: DIRECTOR_VERIFY_CATEGORY,
        error: 'not all documents uploaded',
      });
      return message.error(
        'Please upload all required documents to continue',
        5
      );
    }

    const payload = {
      storage_url: document.storage_url,
      label: document.label,
    };
    return onSubmit([payload]);
  };

  return (
    <div className="space-y-6">
      <ChooseIdType onChange={onChange} value={idType} />
      {idType === 'idBook' && (
        <IDUploader
          type="idBook"
          description="The government will require a certified copy of your ID document to complete your registration."
          requirementsDescription="Submitting an incorrect document may result in longer processing times ⏳"
          requirements={idBookRequirements}
          handleUpload={handleUpload}
          isUploading={document.isUploading}
          uploaded={document.uploaded}
          loaded={document.loaded}
          total={document.total}
        />
      )}
      {idType === 'passport' && (
        <IDUploader
          type="passport"
          description="The government will require a certified copy of your passport to complete your registration."
          requirementsDescription="Submitting an incorrect document may result in longer processing times ⏳"
          requirements={passportRequirements}
          handleUpload={handleUpload}
          isUploading={document.isUploading}
          uploaded={document.uploaded}
          loaded={document.loaded}
          total={document.total}
        />
      )}
      {idType && (
        <div className="flex justify-end">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting ? 'Saving' : 'Save and continue'}
          </Button>
        </div>
      )}
    </div>
  );
};

UploadID.propTypes = {
  directorID: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default UploadID;
