import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 1em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ant-spin-dot {
    margin-bottom: 24px;
  }
`;

export type InlineLoadingProps = {
  label?: string;
};

const InlineLoading = ({ label = 'Loading...' }: InlineLoadingProps) => (
  <Wrapper>
    <Spin
      size="large"
      indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
    />
    {label && <div>{label}</div>}
  </Wrapper>
);

export default InlineLoading;
