import { FileAddOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div``;

const IconWrapper = styled.div`
  font-size: 48px;
  line-height: 45px;
  color: #40a9ff;
  margin-bottom: 8px;
`;

const Title = styled.h3`
  font-weight: 500;
  margin-bottom: 15px;
`;

const Description = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 15px;
`;

interface DefaultProps {
  label: string;
}

const Default = ({ label }: DefaultProps) => {
  return (
    <Wrapper>
      <IconWrapper>
        <FileAddOutlined />
      </IconWrapper>
      <Title>{`Upload ${label}`}</Title>
      <Description>
        Accepted Types: jpg, png or pdf <br /> Minimum Size: 300kb
      </Description>
      <Button type="primary">Select file</Button>
    </Wrapper>
  );
};

Default.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Default;
