import axios from 'axios';

export const getIpAddress = () =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: 'https://api64.ipify.org?format=json',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });
