import React from 'react';
import { Form, Modal, Button } from 'antd';

import Document from './DocumentViewer';
import Signature from './SignatureSection';

const FormItem = Form.Item;

export type SignatureDocumentSubmitPayload = {
  fullName: string;
  signatureImage: Blob;
};

export type SignatureDocumentProps = {
  name: string;
  content: string;
  onSubmit: (payload: SignatureDocumentSubmitPayload) => void;
  isSubmitting: boolean;
};

export const SignatureDocument = ({
  name,
  content,
  onSubmit,
  isSubmitting,
}: SignatureDocumentProps) => {
  const [hasSigned, setHasSigned] = React.useState(false);
  const [signatureImage, setSignatureImage] = React.useState<Blob | null>();
  const [showSignError, setShowSignError] = React.useState(false);
  const [fullName, setFullName] = React.useState('');

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue({ fullName: name });
    setFullName((p) => name || p);
  }, [form, name]);

  const onFinish = () => {
    if (!hasSigned) {
      setShowSignError(true);
      return Modal.error({
        title: 'Missing Signature',
        content: 'You must add your signature in the block to continue',
      });
    }

    if (signatureImage) {
      const payload = {
        fullName: form.getFieldValue('fullName'),
        signatureImage,
      };
      return onSubmit(payload);
    }
    return null;
  };

  const onChange = (values: any) => {
    setFullName(values.fullName);
  };

  const handleSign = (canvas: any) => {
    setHasSigned(true);
    setSignatureImage(canvas);
    setShowSignError(false);
  };

  const handleClearSignature = () => {
    setHasSigned(false);
    setSignatureImage(null);
  };

  return (
    <div>
      <Document content={content} />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onValuesChange={onChange}
      >
        <Signature
          onSign={handleSign}
          onClear={handleClearSignature}
          error={showSignError}
          fullName={fullName && fullName.toUpperCase()}
        />
        <FormItem style={{ float: 'right' }}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={isSubmitting}
          >
            {isSubmitting ? 'Saving' : '"Accept and continue"'}
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default SignatureDocument;
