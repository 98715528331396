import { Upload } from 'antd';
import styled from 'styled-components';

import Default from './Default';
import Uploading from './Uploading';

const { Dragger } = Upload;

const Wrapper = styled.div`
  .ant-upload.ant-upload-drag {
    padding: 1px 0px;
  }
`;

const Inner = styled.div`
  & > div {
    min-height: 166px;
  }
`;

interface UploadAreaProps {
  type: string;
  label: string;
  handleUpload: (file: File, type: string) => void;
  isUploading: boolean;
  uploaded: boolean;
  loaded: number;
  total: number;
}

const UploadArea = ({
  type,
  label,
  handleUpload,
  isUploading,
  uploaded,
  loaded,
  total,
}: UploadAreaProps) => {
  return (
    <Wrapper>
      <Dragger
        name="file"
        accept="image/*, application/pdf, image/heic"
        action="memory"
        multiple={false}
        beforeUpload={(file) => handleUpload(file, type)}
        disabled={isUploading || uploaded}
        // accept=".pdf, .jpeg, .jpg, .png, .tiff, .tif"
      >
        <Inner>
          {!isUploading && !uploaded ? (
            <Default label={label} />
          ) : (
            <Uploading loaded={loaded} total={total} />
          )}
        </Inner>
      </Dragger>
    </Wrapper>
  );
};

export default UploadArea;
