import { Alert } from 'antd';
import styled from 'styled-components';

import Checklist from 'common/components/Checklist/Checklist';

export const Wrapper = styled.div``;

export const Title = styled.h3`
  margin-bottom: 16px;
`;

export const AlertWrapper = styled.div`
  margin-bottom: 2em;
`;

export const ChecklistWrapper = styled.div`
  margin-bottom: 2em;
`;

interface RequirementsProps {
  description: string;
  requirements: Array<{
    id: string;
    label: string;
  }>;
}

const Requirements = ({ description, requirements }: RequirementsProps) => {
  return (
    <Wrapper>
      <Title>Make sure it is certified correctly</Title>
      <AlertWrapper>
        <Alert message={description} type="warning" />
      </AlertWrapper>
      <ChecklistWrapper>
        <Checklist items={requirements} />
      </ChecklistWrapper>
    </Wrapper>
  );
};

export default Requirements;
