import { Card, Radio, RadioChangeEvent, Space } from 'antd';

const RadioGroup = Radio.Group;

interface ChooseIdTypeProps {
  onChange: (e: RadioChangeEvent) => void;
  value: string;
}

const ChooseIdType = ({ onChange, value }: ChooseIdTypeProps) => (
  <section>
    <Card title="Choose your ID type">
      <p className="mb-4">
        Select your ID document type from the options below
      </p>
      <RadioGroup onChange={onChange} value={value}>
        <Space direction="vertical">
          <Radio value="idBook" style={{ marginBottom: '5px' }}>
            ID document
          </Radio>
          <Radio value="passport" style={{ marginBottom: '5px' }}>
            Passport (Non-South African Citizens Only)
          </Radio>
        </Space>
      </RadioGroup>
    </Card>
  </section>
);

export default ChooseIdType;
