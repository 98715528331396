import heic2any from 'heic2any';

export const dataURLtoBlob = (dataurl: any) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const heicToJpg = async (file: File): Promise<File> => {
  const imageBlob = new Blob([file], { type: file.type });
  // @ts-ignore
  const newBlob: Blob = await heic2any({
    blob: imageBlob,
    toType: 'image/jpeg',
  });
  const filename = file.name.replace(/heic/g, 'jpg');
  const newFile = new File([newBlob], filename, { type: newBlob.type });
  return newFile;
};
