import React from 'react';
import styled from 'styled-components';
import Content from '../Page/Content';

const appBarHeight = 64;

const MainContainer = styled.div`
  display: flex;
  min-height: 100vh;
  margin-top: -${appBarHeight}px;
  padding-top: ${appBarHeight}px;
  background: white;
  @media (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 60px;
  }
`;

const ContentContainer = styled.div`
  background: white;
  flex: 1;
  padding: 24px 16px;
  .company-compliance-status {
    margin-bottom: 2em;
  }
`;

type PublicPageProps = {
  children: React.ReactNode;
};

const PublicPage = ({ children }: PublicPageProps) => {
  return (
    <>
      <MainContainer>
        <ContentContainer>
          <Content
            // @ts-ignore
            contentWidth="thin"
            title="Shared Invoice"
          >
            {children}
          </Content>
        </ContentContainer>
      </MainContainer>
    </>
  );
};

export default PublicPage;
