import { Form, Card, Input } from 'antd';
import styled from 'styled-components';

import SignatureInput from './SignatureInput';

const FormItem = Form.Item;

const Wrapper = styled.div`
  .ant-form-item-label {
    white-space: normal;
    display: block;
    width: 100%;
    text-align: left;
  }
  .ant-input {
    text-transform: uppercase;
  }
`;

export type SignatureSectionProps = {
  fullName?: string;
  onSign: (blob: Blob) => void;
  onClear: () => void;
  error: boolean;
};

const SignatureSection = ({
  onSign,
  onClear,
  error,
  fullName = '',
}: SignatureSectionProps) => (
  <Wrapper className="signature-form">
    <Card title="Sign Online" style={{ marginBottom: '2em' }}>
      <FormItem
        name="fullName"
        label="Confirm your full name (as per ID document)"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Input size="large" />
      </FormItem>
      <p>
        <strong>Draw your signature in the block below</strong>
      </p>
      <SignatureInput onSign={onSign} onClear={onClear} error={error} />
      <p>
        I agree that i am {fullName} and this is a legal representation of my
        signatures for all purposes just the same as a pen-and-paper signature.
      </p>
    </Card>
  </Wrapper>
);

export default SignatureSection;
