export const provinces = [
  {
    label: 'Eastern Cape',
    value: 'EASTERN CAPE',
  },
  {
    label: 'Free State',
    value: 'FREE STATE',
  },
  {
    label: 'Gauteng',
    value: 'GAUTENG',
  },
  {
    label: 'KwaZulu-Natal',
    value: 'KWA-ZULU NATAL',
  },
  {
    label: 'Limpopo',
    value: 'LIMPOPO',
  },
  {
    label: 'Mpumalanga',
    value: 'MPUMALANGA',
  },
  {
    label: 'Northern Cape',
    value: 'NORTHERN CAPE',
  },
  {
    label: 'North West',
    value: 'NORTH WEST',
  },
  {
    label: 'Western Cape',
    value: 'WESTERN CAPE',
  },
];
