import { useSelector } from 'react-redux';
import SignatureDocument from './SignatureDocument';

import { createFullName } from '../../utils/strings';

export type SignatureDocumentProviderProps = {
  content: string;
  onSubmit: (payload: any) => void;
  isSubmitting: boolean;
};

const SignatureDocumentProvider = ({
  content,
  onSubmit,
  isSubmitting,
}: SignatureDocumentProviderProps) => {
  const firstName = useSelector((state: any) => state.user.firstName);
  const lastName = useSelector((state: any) => state.user.lastName);
  return (
    <SignatureDocument
      name={createFullName(firstName, lastName)}
      content={content}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
    />
  );
};

export default SignatureDocumentProvider;
