// https://stackoverflow.com/questions/13344294/south-african-id-number-validate-and-get-age-and-gender/13344544

import { differenceInYears } from 'date-fns';

const TODAYSDATE = new Date();

export const sumOddIDNumbers = (idNumber: string) => {
  let result = 0;
  for (let i = 0; i < 12; i += 2) {
    result += Number(idNumber[i]);
  }
  return result;
};

export const createEvenNumberString = (idNumber: string) => {
  let result = 0;
  for (let i = 1; i < 12; i += 2) {
    // @ts-ignore
    result += idNumber[i];
  }
  return Number(result) * 2;
};

export const sumString = (string: string) =>
  // @ts-ignore
  [...string].reduce((a, b) => Number(a) + Number(b), 0);

export const calculateIDControlDigit = (idNumber: string) => {
  let result = 10;
  const sumNumbers =
    sumOddIDNumbers(idNumber) +
    sumString(createEvenNumberString(idNumber).toString());
  result -= sumNumbers.toString()[1];
  if (result === 10) {
    return 0;
  }
  return result;
};

export const isChecksumValid = (idNumber: string) => {
  if (
    calculateIDControlDigit(idNumber) === Number(idNumber[idNumber.length - 1])
  ) {
    return true;
  }
  return false;
};

export const getBirthdateFromIDNumber = (idNumber: string) => {
  let birthYear = idNumber.slice(0, 2);
  if (birthYear[0] === '0') {
    birthYear = `20${birthYear}`;
  } else {
    birthYear = `19${birthYear}`;
  }
  const birthMonth = idNumber.slice(2, 4);
  const birthDay = idNumber.slice(4, 6);
  const birthDate = `${birthYear}-${birthMonth}-${birthDay}`;
  return birthDate;
};

export const isOver18 = (idNumber: string, todaysDate: Date) => {
  let result = true;
  const birthDate = getBirthdateFromIDNumber(idNumber);
  const yearsOld = differenceInYears(todaysDate, birthDate);
  if (yearsOld < 18) {
    result = false;
  }
  return result;
};

export const validateIdentityNumber = (_rule: any, value: string) => {
  const idNumber = value;
  if (idNumber.length !== 13) {
    return Promise.reject(new Error('ID number must be 13 digits'));
  }
  if (idNumber.length === 13 && !isChecksumValid(idNumber)) {
    return Promise.reject(new Error('ID number is not valid'));
  }
  if (idNumber.length === 13 && !isOver18(idNumber, TODAYSDATE)) {
    return Promise.reject(
      new Error('Company directors must be over 18 years old')
    );
  }
  return Promise.resolve();
};
