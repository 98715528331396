export const idBookRequirements = [
  {
    id: '1',
    label: 'Signed & stamped by a commissioner of oaths',
  },
  {
    id: '2',
    label: 'Name of the commissioner of oaths is visible',
  },
  {
    id: '3',
    label: 'Clear date of certification',
  },
  {
    id: '4',
    label: 'Certified within the last 3 months',
  },
  {
    id: '5',
    label: 'For Smart ID cards, both front and back sides on a single page',
  },
  {
    id: '6',
    label: 'Photo or scan is clear and all details are clearly visible',
  },
];

export const passportRequirements = [
  {
    id: '1',
    label: 'Signed & stamped by a commissioner of oaths',
  },
  {
    id: '2',
    label: 'Name of the commissioner of oaths is visible',
  },
  {
    id: '3',
    label: 'Clear date of certification',
  },
  {
    id: '4',
    label: 'Certified within the last 3 months',
  },
  {
    id: '6',
    label: 'Photo or scan is clear and all details are clearly visible',
  },
];
